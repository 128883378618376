import "../App.css"
import Formulario from "../Components/Formulario";
import ThumPD from "../Img/Proyectos/ProyectosDesarrollo/ThumPD.jpg"
import ThumPCD from "../Img/Proyectos/ProyectosCasasDescanso/ThumPCD.jpg"
import ThumPR from "../Img/Proyectos/ProyectosResidenciales/ThumPR.jpg"
import ThumPT from "../Img/Proyectos/ProyectosTurismo/ThumPT.jpg"
import ThumIyC from "../Img/Proyectos/ProyectosIyC/ThumPIyC.jpg"
import CarrouselImg2 from "../Img/Carrousel/61cb730a39c94d781d92ffe0_slide3_alfonso_2.jpg"

import CarrouselImg4 from "../Img/Carrousel/villa-belha-riviera-maya-1-46.jpg"
import CarrouselImg5 from "../Img/Carrousel/villa-belha-riviera-maya-1-89-1.jpg"
import CarrouselImg6 from "../Img/Carrousel/casa-cocula (6).jpg"
import CarrouselImg7 from "../Img/Carrousel/Casa.ElPrado.4.jpg"
import ModalNewsletter from "../Components/ModalNewsletter";
import PictureArqGuia from "../Img/Blogs/Guia-Arquitecto/adult-architect-blueprint-416405-1-e1558802628675.webp"
import PictureArquitecturaInventarDescubrirPrincipal from "../Img/Blogs/ArquitecturaInventarDescubrir/61ce8847be32b1b5fbef299a_blog3.webp"
import PictureObjetivoArquitecturaPrincipal from "../Img/Blogs/ObjetivoArquitectura/seguridad-hogar-vacaciones-1200x800.jpg"

function Home() {
  return (
    <>

      <ModalNewsletter />

      <section>
        <div className="container col-12 mt-2">
          <h1 className="fw-light h2 lh-sm">
            Transformamos tus <label className="pf_italic">ideas</label> y <label className="pf_italic">sueños</label><br /> en espacios
            en donde vivir en paz.
          </h1>
        </div>
      </section>

      <div className="container col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-5">
        <div id="carouselExampleInterval" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active" data-bs-interval="2000">
              <img src={CarrouselImg2} class="d-block w-100" alt="Imagen Carrusel" />
            </div>
            <div class="carousel-item" data-bs-interval="2000">
              <img src={CarrouselImg4} class="d-block w-100" alt="Imagen Carrusel" />
            </div>
            <div class="carousel-item" data-bs-interval="2000">
              <img src={CarrouselImg5} class="d-block w-100" alt="Imagen Carrusel" />
            </div>
            <div class="carousel-item" data-bs-interval="2000">
              <img src={CarrouselImg6} class="d-block w-100" alt="Imagen Carrusel" />
            </div>
            <div class="carousel-item" data-bs-interval="2000">
              <img src={CarrouselImg7} class="d-block w-100" alt="Imagen Carrusel" />
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>

      <div className="container col-12 col-sm-12 col-md-10 col-lg-12 col-xl-9">

        <div className="row align-items-top gy-3 py-5">

          <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <div class="card h-100">
              <h1 className="fw-light m-0 p-0">Arquitectura contemporánea y funcional</h1>
            </div>
          </div>

          <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <div class="card h-100 rounded-0">
              <h6 className="lh-base fw-light">En <b className="fw-bold">Alfonso Núñez Arquitectos</b>, contamos con más de 30 años de experiencia en el desarrollo de proyectos arquitectónicos. Nos destacamos por diseñar y crear espacios funcionales, vanguardistas y contemporáneos.</h6>
              <h6 className="lh-base fw-light">Nuestra experiencia profesional se extiende desde nuestra sede en Guadalajara hasta diversas localidades en todo México.</h6>
              <a href="/nosotros" type="button" class="btn btn-outline-dark rounded-0 col-12 col-md-3 mt-3 btn-lg">Leer más</a>
            </div>
          </div>
        </div>

      </div>


      <section className="img-bg-comienza min-vh-100 d-flex flex-column text-center justify-content-center align-items-center position-relative">
        <div className="overlay2 position-absolute w-100 h-100"></div>
        <div className="container  position-relative col-md-5">
          <h1 className="text-white mb-4 lh-sm fw-light text-center">Damos vida a tus ideas y sueños, creando espacios únicos y de calidad</h1>
          <a type="button" href="/contacto" className="btn btn-light rounded-0 btn-lg ">Hablemos de tu proyecto</a>
        </div>
      </section>

      <section>
        <div className="container col-md-12">
          <h1 className="mt-5 lh-1 py-4 fw-light text-uppercase fw-bold ls-0_5px ">Proyectos</h1>

          <div class="row row-cols-1 row-cols-md-3">

            <div class="col">
              <div class="card h-100">
                <a href="/proyectos-residenciales">
                  <img src={ThumPR} class="card-img-top h300" alt="Imagen de Residenciales" />
                  <div class="my-4">
                    <h6 class="card-title text-uppercase ls-0_5px fw-bold small">Residencial</h6>
                  </div>
                </a>
              </div>
            </div>

            <div class="col">
              <div class="card h-100">
                <a href="/proyectos-playa-y-montana">
                  <img src={ThumPCD} class="card-img-top h300" alt="Imagen Casas de Descanso" />
                  <div class="my-4">
                    <h6 class="card-title text-uppercase ls-0_5px fw-bold small">Playa y Montaña</h6>
                  </div>
                </a>
              </div>
            </div>

            <div class="col">
              <div class="card h-100">
                <a href="/proyectos-hoteles">
                  <img src={ThumPT} class="card-img-top h300" alt="Imagen de Turismo" />
                  <div class="my-4">
                    <h5 class="card-title text-uppercase ls-0_5px fw-bold small">Hoteles</h5>
                  </div>
                </a>
              </div>
            </div>

            <div class="col">
              <div class="card h-100">
                <a href="/proyectos-varios">
                  <img src={ThumIyC} class="card-img-top h300" alt="Imagen de Industrial y comercial" />
                  <div class="my-4">
                    <h5 class="card-title text-uppercase ls-0_5px fw-bold small">Comercial</h5>
                  </div>
                </a>
              </div>
            </div>

            <div class="col">
              <div class="card h-100">
                <a href="/proyectos-en-desarrollo">
                  <img src={ThumPD} class="card-img-top h300" alt="Imagen Desarrollos" />
                  <div class="my-4">
                    <h6 class="card-title text-uppercase ls-0_5px fw-bold small">Varios</h6>
                  </div>
                </a>
              </div>
            </div>

          </div>

        </div>
      </section>


      <section>
        <div className="container">

          <h1 className="mt-5 lh-1 py-4 fw-light text-uppercase fw-bold ls-0_5px ">Blog</h1>

          <div class="row justify-content-between gy-4">

            <div class="col-12 col-sm-12 col-lg-6 col-xl-6">
              <a href="/guia-para-contratar-servicios-arquitectonicos">
                <div class="card">
                  <img src={PictureArqGuia} class="img-blog" alt="Imagen de blog" />
                  <div class="card-body">
                    <h6 class="fw-light">10 de agosto, 2024</h6>
                    <h3 class="fw-medium my-2">Guía para contratar servicios arquitectónicos</h3>
                  </div>
                </div>
              </a>
            </div>

            <div class="col-12 col-sm-12 col-lg-6 col-xl-6">

              <div class="row g-4 row justify-content-between">
                <div class="col-12">
                  <a href="/el-objetivo-no-es-la-arquitectura-sino-lo-que-permitimos-que-suceda-en-ella">
                    <div class="card h-100">
                      <div class="row g-0 align-items-center">
                        <div class="col-md-4">
                          <img src={PictureObjetivoArquitecturaPrincipal} class="img-blog-home" alt="Imagen Blog" />
                        </div>
                        <div class="col-md-8">
                          <div class="card-body">
                            <h6 class="">1 de octubre, 2019</h6>
                            <h3 class="card-title">El objetivo no es la arquitectura, sino lo que permitimos que suceda en ella…</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>

                <div class="col-12">
                  <a href="/arquitectura-inventar-o-descubrir">
                    <div class="card h-100">
                      <div class="row g-0 align-items-center">
                        <div class="col-md-4">
                          <img src={PictureArquitecturaInventarDescubrirPrincipal} class="img-blog-home" alt="Imagen Blog" />
                        </div>
                        <div class="col-md-8">
                          <div class="card-body">
                            <h6 class="">10 de agosto, 2019</h6>
                            <h3 class="card-title">Arquitectura: ¿Inventar o Descubrir?</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>

              </div>
            </div>

          </div>
          <div className="container text-center mt-3">
            <a href="/blog" type="button" class="btn btn-primary rounded-0 col-12 col-md-3 mt-3 btn-lg">Ver todos</a>
          </div>
        </div>

      </section>



      <div class="container col-12 col-sm-12 col-md-12 col-lg-10 col-xl-4 mx-auto my-5 pt-5">
        <h2 className=" text-center pb-5">Déjanos tus datos. <br />Te contactaremos a la brevedad.</h2>
        <Formulario />
      </div>

    </>
  );
}

export default Home;
